<template>
  <div>
    <!-- test -->
    <v-app-bar flat class="mt-2 mb-2">
      <v-row row wrap class="py-3">
        <v-col cols="12">
          <h3 class="text-voucherno">{{ voucherDetail.voucher_number }}</h3>
        </v-col>
        <!-- <v-col cols="4" align="end">
            <div class="btnhistory" @click="openHistory">{{$t("SigninVoucherList.title_history")}}</div>
          </v-col> -->
        <v-col cols="4" style="margin-top: -20px">
          <div class="btntext" style="cursor: pointer" @click="getPDFFileFunc(voucherDetail)">
            <v-icon left small>mdi-file-outline</v-icon>
            <span class="mt-2">{{ $t("SigninVoucherList.title_pdf_print") }}</span>
          </div>
        </v-col>
        <v-col cols="4" style="margin-top: -20px">
          <div class="btntext">
            <v-icon left small>mdi-email-outline</v-icon>
            <span class="mt-2">{{ $t("SigninVoucherList.title_mail") }}</span>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>
    <div v-if="show_overlay">
      <v-overlay>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <div style="height: 750px; overflow-y: auto; padding-bottom: 20px" class="mr-3">
      <div class="page pa-3">
        <div class="subpage">
          <v-row row wrap class="mx-1 mt-2">
            <v-col cols="5">
              <div class="detail_bold">{{ property_name }}</div>
              <!-- Ordered Reservation -->
              {{
  $t(`SigninPayout.${voucherDetail.status
}`) ||
              voucherDetail.status ||
              $t("SignupStep.nodata")
              }}
            </v-col>
            <v-col cols="7" class="detail_bold">
              <div>
                <span class="detail_bold" v-if="voucherDetail.booking_date">{{ voucherDetail.booking_date }} -
                </span>
                <span class="detail_bold" v-if="voucherDetail.night === 1">{{ voucherDetail.night }} {{
                $t("SigninVoucherList.night_unit") }} -
                </span>
                <span class="detail_bold" v-else-if="voucherDetail.night > 1">{{ voucherDetail.night }} {{
                $t("SigninVoucherList.night_unit") }} -
                </span>
                <span class="detail_bold" v-if="guestDetail">{{ guestDetail.first_name }} {{ guestDetail.last_name
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" class="margin-divider"><v-divider></v-divider></v-col>
            <v-col cols="4">
              <div class="topic_small">{{ $t("SigninVoucherList.title_checkin") }}</div>
              <div class="detail_bold">
                {{ checkinDate }}
                <v-icon small right v-if="checkinDate !== '-'">mdi-pencil-outline</v-icon>
              </div>
              <div class="topic_small">{{ $t("SigninVoucherList.title_checkout") }}</div>
              <div class="detail_bold">{{ checkoutDate }}</div>
              <div class="topic_small">
                {{ $t("SigninVoucherList.title_length_of_stay") }}:
              </div>
              <div v-if="voucherDetail.night" class="detail">
                <div v-if="voucherDetail.night === 1">
                  {{ voucherDetail.night }} night
                </div>
                <div v-else-if="voucherDetail.night > 1">
                  {{ voucherDetail.night }} nights
                </div>
              </div>
              <div v-else>-</div>
              <div class="topic_small">
                {{ $t("SigninVoucherList.title_total_guests") }}:
              </div>
              <div class="detail">{{ voucherDetail.total_guest || "-" }}</div>
              <!-- <div class="topic_small">
                {{ $t("SigninVoucherList.title_total_units") }}
              </div>
              <div v-if="voucherDetail.qty" class="detail">{{ voucherDetail.qty }}</div>
              <div v-else class="detail">-</div>
              <div class="topic_small">Net. Price</div>
              <div v-if="voucherDetail.total" class="detail_bold">
                {{ $t("SigninVoucherList.title_thb") }} {{ voucherDetail.total }}
              </div>
              <div v-else class="detail_bold">-</div> -->
            </v-col>
            <v-col cols="8">
              <span class="topic_small">{{
              $t("SigninVoucherList.title_guest_name")
              }}</span>
              <div class="detail_bold" v-if="guestDetail">
                {{ guestDetail.first_name }} {{ guestDetail.last_name }}
              </div>
              <v-row>
                <v-col cols="4">
                  <div class="topic_small">
                    <!-- {{$t("SigninVoucherList.title_booking_number")}}: -->
                    {{ $t("SigninVoucherList.title_voucher_number") }}
                  </div>
                  <div class="detail">{{ voucherDetail.voucher_number || "-" }}</div>
                </v-col>
                <v-col cols="4">
                  <div class="topic_small">
                    {{ $t("SigninVoucherList.title_room_charge") }}
                  </div>
                  <div class="detail">
                    {{ $t("SigninVoucherList.title_thb") }} {{ voucherDetail.room_charge }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="topic_small">
                    {{ $t("SigninVoucherList.title_extra_charge") }}
                  </div>
                  <div class="detail">
                    {{ $t("SigninVoucherList.title_thb") }}
                    {{ voucherDetail.extra_charge }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="topic_small">
                    {{ $t("SigninVoucherList.title_received") }}:
                  </div>
                  <div class="detail">
                    {{ $t("SigninVoucherList.title_thb") }}
                    {{ voucherDetail.received || "-" }}
                  </div>
                </v-col>
                <v-col cols="4">
                  <span class="topic_small">{{
                  $t("SigninVoucherList.title_total_charge")
                  }}</span>
                  <div class="detail">
                    {{ $t("SigninVoucherList.title_thb") }} {{ voucherDetail.total_charge || "-" }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="margin-divider"><v-divider></v-divider></v-col>
            <v-col cols="12">
              <div class="detail_normal mb-2">
                <span class="mr-2 detail_normal">{{ roomDetail.room_type }} ({{ roomDetail.room_name }})</span>
                <!-- <span class="detail_normal">{{$t("SigninVoucherList.title_thb")}} {{ voucherDetail.rice }}</span> -->
              </div>
              <div class="detail">
                <span class="mr-6">
                  <v-icon left small>mdi-exit-to-app</v-icon>
                  {{ checkinDate }}
                </span>
                <span class="mr-6">
                  <v-icon left small>mdi-export</v-icon>
                  {{ checkoutDate }}
                </span>
                <span v-if="voucherDetail.food_service"><v-icon left>mdi-food-croissant</v-icon>{{
                voucherDetail.food_service }}</span>
              </div>
            </v-col>
            <v-col cols="12" class="margin-divider"><v-divider></v-divider></v-col>
            <v-col cols="4">
              <div class="topic_small">
                {{ $t("SigninVoucherList.title_guest_name2") }}:
              </div>
              <div class="detail">
                <div v-if="guestDetail">
                  {{ guestDetail.first_name }} {{ guestDetail.last_name }}
                </div>
              </div>
              <div class="topic_small">
                {{ $t("SigninVoucherList.title_max_occupancy") }}
              </div>
              <div class="detail">{{ voucherDetail.room_detail.customer.max }}</div>
              <div class="topic_small">
                {{ $t("SigninVoucherList.title_meal_options") }}
              </div>
              <div class="detail">
                {{ voucherDetail.rate_plan_detail.food_service || "-" }}
              </div>
              <div class="topic_small">
                {{ $t("SigninVoucherList.title_room_photo") }}
              </div>
              <div class="detail">
                <img :src="roomImage" width="100%" class="mt-2" />
              </div>
            </v-col>
            <v-col cols="8">
              <div style="width: 100%; overflow-x: auto; margin: 10px 0px">
                <table style="font-size: 14px; font-weight: 200px">
                  <tr style="text-align: center">
                    <th width="30%">{{ $t("SigninVoucherList.title_order_date") }}</th>
                    <th width="40%">{{ $t("SigninVoucherList.title_rate") }}</th>
                    <th width="30%">
                      {{ $t("SigninVoucherList.title_price_per_night") }}
                    </th>
                  </tr>
                  <tr>
                    <td>{{ voucherDetail.order_date }}</td>
                    <td class="mx-1">
                      {{ voucherDetail.rate }} ({{
                      voucherDetail.rate_plan_detail.rate_plan ||
                      voucherDetail.rate_plan_detail.name
                      }})
                    </td>
                    <td style="text-align: center">
                      THB {{ voucherDetail.price_per_night }}
                    </td>
                  </tr>
                </table>
              </div>
            </v-col>
            <v-col cols="4" />
            <v-col cols="8">
              <v-layout row wrap class="mx-1 line-top">
                <div class="price-text">{{ $t("SigninVoucherList.title_subtotal") }}</div>
                <v-spacer></v-spacer>
                THB {{ voucherDetail.received }}
              </v-layout>
              <v-layout row wrap class="mx-1 line-top">
                <div class="price-text">
                  {{ $t("SigninVoucherList.title_total_room_price") }}
                </div>
                <v-spacer></v-spacer>
                THB {{ voucherDetail.received }}
              </v-layout>
              <v-layout row wrap class="mx-1 line-top">
                <div class="include-text">
                  {{ $t("SigninVoucherList.title_rate_includes") }}
                </div>
                <v-spacer></v-spacer>
                <div class="include-text">{{ $t("SigninVoucherList.vat") }} 7%</div>
              </v-layout>
              <v-layout row wrap class="mx-1 line-top">
                <div class="include-text">
                  {{ $t("SigninVoucherList.title_rate_includes") }}
                </div>
                <v-spacer></v-spacer>
                <div class="include-text">
                  10% {{ $t("SigninVoucherList.service_charge") }}
                </div>
              </v-layout>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="page pa-3">
        <v-row row wrap class="mx-1 mt-2 mb-6">
          <v-col cols="12">
            <div class="text-bold text-14">Guest request</div>
          </v-col>
          <v-col cols="3"></v-col>
          <v-col cols="9" align="center">
            <div class="text-bold text-14" v-if="voucherDetail.guest_request == null">No messages</div>
            <div class="text-bold text-14" v-else>{{ voucherDetail.guest_request }}</div>
            <!-- <div class="text-normal text-14">
              Your conversations with your guests will appear here
            </div> -->
          </v-col>
          <v-col cols="12" class="margin-divider"><v-divider></v-divider></v-col>
          <v-col cols="12">
            <div class="text-normal text-14">
              aigentcorporation.com receives all messages written here and processes them
              according to our
              <span style="color: #1e9ff2">Privacy & Cookie Statement</span>
            </div>
            <div class="text-bold mt-6">
              {{ roomDetail.room_type }} ({{ roomDetail.room_name }})
            </div>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="4">
            <div class="text-bold text-14">Modification Policy</div>
          </v-col>
          <v-col cols="8">
            <div class="text-normal" v-if="policy_change_date != null">
              <span class="text-14" v-if="policy_change_date.can_before">
                Guests can reschedule their stay free of charge prior to their arrival date ({{ policy_change_date.free_before }}) day(s). If changing the date of stay during the specific period, the guest will be charged the specific price. And the guest cannot change the date of stay again ({{ policy_change_date.can_before }}) day(s) before the check-in date.
              </span>
              <span class="text-14" v-else>Guests will not change the reservation after requesting a
                reservation</span>
            </div>
            <div class="text-normal" v-else>
              <span class="text-14">Guests will not change the reservation after requesting a
                reservation</span>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="text-bold text-14">Extra bed policy for children</div>
          </v-col>
          <v-col cols="8">
            <div v-for="(item, index) in policy_child" :key="index">
              <div class="text-normal text-14 mt-1">
                Old age {{ item.age.min }} to {{ item.age.max }} year(s) will be charged
                at the service fee charged {{ item.percent }} % of the price of a bed for a child as you
                have specified in that room type
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="text-bold text-14">Other Policie(s)</div>
          </v-col>
          <v-col cols="8">
            <div v-if="policy_cancel" class="text-normal text-14">
              <b>Ignore Cancellation fees</b>
              <br />
              Cancellations before 6.30 pm after booking on the same day : Covers most
              cancellations that may be due to booking errors (cancellations do not
              include group bookings that have been completed).
            </div>
            <br />
            <div class="text-normal text-14">
              <b>Customer Information</b>
              <br />
              Check-in and check-out time Check in from {{ check_in_min }} to {{ check_in_max }} /
              Check out from {{ check_out_min }} to {{ check_out_max }}
            </div>
            <br />
            <div v-if="age_min == ''" class="text-normal text-14">
              <b>Age Limit</b>
              <br />
              No age limit for guests
            </div>
            <div v-else class="text-normal text-14">
              <b>Age Limit</b>
              <br />
              Age limit for guests from {{ age_min }} to {{ age_max }}
            </div>
            <br />
            <div class="text-normal text-14">
              <b>Pets and Parking</b>
              <br />
              Pets : {{ pets ? 'Pets are allowed at the property.' : 'Not allowed' }} / Parking : {{ parking == '' ? 'no parking' : parking }}
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
export default {
  data() {
    return {
      property_name: "",
      show_overlay: true,
      policy_child: [],
      policy_cancel: false,
      check_in: "",
      check_out: "",
      check_in_min: "",
      check_in_max: "",
      check_out_min: "",
      check_out_max: "",
      age_min: "",
      age_max: "",
      aigentTotal: 0,
      pets: false,
      parking: "",
      policy_change_date: null,
    };
  },
  props: {
    voucherList: { type: Array, default: null },
    voucherDetail: { type: Object, default: null },
  },
  created() {
    this.property_name = localStorage.getItem("property_name");
    this.InitGetPolicy();
    this.aigentTotal = (
      this.voucherDetail.price - this.voucherDetail.voucher_price_net
    ).toFixed(2);
    console.log("voucherDetail: ", this.voucherDetail);
  },
  computed: {
    roomDetail() {
      // console.log("voucherDetail: ", this.voucherDetail);
      return this.voucherDetail.room_detail;
    },
    guestDetail() {
      return this.voucherDetail.guest;
    },
    checkinDate() {
      const checkIn =
        this.voucherDetail.stay_date !== " - "
          ? this.voucherDetail.stay_date.substring(0, 10)
          : "-";
      return checkIn;
    },
    checkoutDate() {
      const checkOut =
        this.voucherDetail.stay_date !== " - "
          ? this.voucherDetail.stay_date.substring(13, 23)
          : "-";
      return checkOut;
    },
    roomImage() {
      const img = `${process.env.VUE_APP_PIC_API}/${this.voucherDetail.pictures}`;
      return img;
    },
  },
  methods: {
    async InitGetPolicy() {
      const self = this;
      self.show_overlay = true;
      try {
        const url = document.URL;
        const token = url.split("/")[5];
        // console.log(token);
        const res = await self.axios.get(
          process.env.VUE_APP_API +
          `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization: token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          const temp = res?.data?.result[0] || null;
          // console.log(temp) 
          this.policy_child = temp?.policy_child || [];
          this.check_in = temp?.check_in || "";
          this.check_out = temp?.check_out || "";
          this.policy_cancel = temp?.policy_cancel_booking || false;
          this.check_in_min = temp?.policy_customer.check_in_range.min || "";
          this.check_in_max = temp?.policy_customer.check_in_range.max || "";
          this.check_out_min = temp?.policy_customer.check_out_range.min || "";
          this.check_out_max = temp?.policy_customer.check_out_range.max || "";
          this.age_min = temp?.policy_customer.age.min || "";
          this.age_max = temp?.policy_customer.age.max || "";
          this.pets = temp?.pet || false;
          this.parking = temp?.parking.value || temp?.parking;
          this.policy_change_date = temp?.policy_change_date[0] || [];

          // console.log("policy_change_date: ", this.policy_change_date);
        }
      } catch (error) {
        // EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        // EventBus.$emit("endloading");
        // self.propertyData = temp;
        // self.propertyPhotos = temp?.pictures || [];
        // self?.propertyPhotos?.sort((a, b) =>
        //   a.sort_number > b.sort_number ? 1 : -1
        // );
        // console.log("propertyPhotos: ", self?.propertyPhotos);
        // self.itemsPhotoHave = tempCategory;
        // self.setAlbumPictures(temp.rooms);
        // EventBus.$emit("refreshAppbarProperty");
        // console.log("propertyPhotos: ", temp?.pictures);
        self.show_overlay = false;
      }
    },
    openHistory() {
      this.dialog = true;
    },
    formatTime(val) {
      return val.substring(0, 5);
    },
    async getPDFFileFunc(item) {
      const self = this;
      // console.log(item);
      EventBus.$emit("loadingtillend");
      self.show_overlay = true;
      try {
        const url = document.URL;
        const token = url.split("/")[5];

        const res = await self.axios.get(
          process.env.VUE_APP_API + `/voucher/gen_pdf_voucher?voucher_id=${item._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}` || self?.userToken || localStorage?.getItem("Token") || self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
            responseType: "blob",
          }
        );
        if (res.status == 200) {
          // console.log(res);
          window.open(URL.createObjectURL(res.data));
          EventBus.$emit("endloading");
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        self.show_overlay = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/bootstrap/global.css";
@import "../../styles/signin/setting.css";

.margin-divider {
  margin-bottom: -3%;
  margin-top: -3%;
}

.positionbtn {
  position: absolute;
  bottom: 20px;
}

.btnshowvoucher {
  height: 40px;
  border-radius: 5px;
  border-color: #1e9ff2 !important;
  background-color: #1e9ff2 !important;
  box-shadow: 0px 1px 20px 1px rgb(30 159 242 / 60%);
}

.btnresetvoucher {
  height: 40px;
  border-radius: 5px;
  border-color: #666ee8 !important;
  background-color: #666ee8 !important;
  box-shadow: 0px 1px 20px 1px rgb(30 159 242 / 60%);
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  // height: 550px;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

th {
  background: white;
  position: sticky;
  top: 0;
  /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid #ababab;
}

.text-header {
  padding: 3% 0 0 0;
  font-size: 14px;
  text-align: center;
}

.text-info {
  color: #1e9ff2 !important;
  font-size: 14px;
  margin: 0;
  line-height: 1.4;
  font-weight: 400;
  text-transform: capitalize;
}

.text-subinfo {
  color: #6b6f82 !important;
  font-size: 11px !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-normal {
  margin: 0;
  line-height: 1.4;
  font-weight: 400;
  font-style: normal;
  color: #111111;
}

.text-error {
  color: #ff4961 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.badge-style {
  display: inline-block;
  background-color: #666ee8;
  margin: 15px 0 0 0;
  padding: 0.35em 0.4em;
  font-size: 80%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  width: 120px;
}

.badge-style .text-badge {
  font-size: 11px !important;
  color: #fff;
  word-wrap: break-word;
}

.btnbackvoucher {
  cursor: pointer;
  font-size: 12px !important;
  margin-top: -28px;
  margin-bottom: 10px;
}

.text-voucherno {
  font-size: 21px !important;
  font-weight: bold;
}

.btnhistory {
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 600;
}

.card {
  border: 0;
  border-radius: 0;
}

.card {
  margin-bottom: 1.875rem;
  border: none;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
  border-radius: 0.45rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.35rem;
}

.page {
  max-height: auto;
  padding: 10mm;
  margin: 2mm auto;
  border: 1px #d3d3d3 solid;
  background: white;
}

.topic_small {
  font-size: 0.75rem !important;
  color: #6c757d;
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif !important;
}

.detail {
  font-size: 14px;
  color: #414042;
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
}

.detail_bold {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #000;
  margin: 0;
  line-height: 1.4;
  font-style: normal;
}

.detail_normal {
  font-size: 18px !important;
  color: #000;
  margin: 0;
  line-height: 1.4;
  font-style: normal;
}

.line-top {
  border-top: 1px solid #bbb;
  margin: 15px 0;
}

.include-text {
  font-size: 14px !important;
  font-weight: 400;
  color: #414042;
  line-height: 1.5;
  font-style: italic;
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
}

.price-text {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #414042;
  line-height: 1.5;
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
}
</style>
