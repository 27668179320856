<template>
  <v-container fluid class="content-wrapper pb-12">
    <v-layout row wrap class="mt-2" v-if="current_tab === 'voucherlist'">
      <div class="d-flex flex-row justify-start align-center mb-5">
        <h4 class="fw-600 mb-0">{{ $t("SigninVoucherList.title") }}</h4>
        <v-btn icon class="ml-3" @click="initAll()" :class="loadingData && 'animate__spining'" :disabled="loadingData">
          <v-icon class="">mdi-reload</v-icon>
        </v-btn>
      </div>

      <v-spacer v-if="!loadingData"></v-spacer>
      <v-btn v-if="!loadingData" text x-small>
        <v-icon left small>mdi-tray-arrow-down</v-icon>
        {{ $t("SigninVoucherList.btn_download") }}
      </v-btn>
      <span v-if="!loadingData" class="mx-1">|</span>
      <v-btn v-if="!loadingData" text x-small>
        <v-icon left small>mdi-printer-outline</v-icon>
        {{ $t("SigninVoucherList.btn_print") }}
      </v-btn>
    </v-layout>
    <v-layout row wrap v-if="current_tab === 'voucherdetail' && !loadingData" class="btnbackvoucher"
      @click="backtoOverview">
      <v-icon left small>mdi-chevron-double-left</v-icon>
      <span class="mt-1">{{ $t("SigninVoucherList.return_voucher_overview") }}</span>
    </v-layout>

    <v-layout row wrap class="mt-2" v-if="current_tab === 'voucherdetail' && !loadingData">
      <h3 class="title-md">{{ $t("SigninVoucherList.title_voucher_detail") }}</h3>
    </v-layout>

    <div v-if="loadingData">
      <v-skeleton-loader type="table-row" class="mb-5"></v-skeleton-loader>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </div>

    <v-row v-if="!loadingData" row wrap class="mb-3">
      <v-col cols="2" lg="2" sm="4" xs="12">
        {{ $t("SigninVoucherList.title_voucher") }}
        <!-- <v-select :items="item_voucher" item-text="text" item-value="value" v-model="voucher_no"
          class="inputtext uppertext vue-select2" color="#1e9ff2" dense outlined></v-select> -->
        <v-autocomplete :items="item_voucher" item-text="text" item-value="value" v-model="voucher_no"
          class="inputtext uppertext" color="#1e9ff2" dense outlined></v-autocomplete>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12">
        {{ $t("SigninVoucherList.title_dateof") }}
        <v-select :items="item_date" item-text="text" item-value="value" v-model="date_of" class="inputtext uppertext"
          color="#1e9ff2" dense outlined></v-select>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12">
        {{ $t("SigninVoucherList.title_type") }}
        <v-select :items="item_type" item-text="text" item-value="value" v-model="type" class="inputtext uppertext"
          color="#1e9ff2" dense outlined></v-select>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12">
        {{ $t("SigninVoucherList.title_from") }}
        <v-menu ref="start_menu" v-model="start_menu" :close-on-content-click="false" transition="scale-transition"
          offset-y min-width="auto" max-width="350px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="start_format" placeholder="dd/mm/yyyy" append-icon="mdi-calendar"
              @click:append="start_menu = true" v-bind="attrs" v-on="on" class="inputtext" color="#1e9ff2" outlined
              dense readonly></v-text-field>
          </template>
          <v-date-picker class="text-picker" width="190" no-title color="#1e9ff2" v-model="start_date"
            @input="formatStart(start_date)"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12">
        {{ $t("SigninVoucherList.title_to") }}
        <v-menu ref="end_menu" v-model="end_menu" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="auto" max-width="350px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="end_format" placeholder="dd/mm/yyyy" append-icon="mdi-calendar"
              @click:append="end_menu = true" v-bind="attrs" v-on="on" class="inputtext" color="#1e9ff2" outlined dense
              readonly></v-text-field>
          </template>
          <v-date-picker no-title width="190" color="#1e9ff2" v-model="end_date" :min="min_enddate"
            :show-current="min_enddate" @input="formatEnd(end_date)"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12">
        {{ $t("SigninVoucherList.title_status") }}
        <v-select :items="item_status" item-text="text" item-value="value" v-model="status" class="inputtext uppertext"
          color="#1e9ff2" dense outlined multiple placeholder="Multi Select" id="multi-status"><template
            v-slot:selection="{ item, index }">
            <div style="max-height: 2.5rem !important">
              <!-- <v-chip > -->
              <span v-if="index === 0">{{
                item.text.length > 8 && status.length > 1
                  ? item.text.slice(0, 8) + "..."
                  : item.text.length > 13
                    ? item.text.slice(0, 13) + "..."
                    : item.text
              }}</span>
              <!-- </v-chip> -->
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ status.length - 1 }})
              </span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="2" lg="2" sm="4" xs="12" class="d-flex flex-row align-end justify-start">
        <v-btn depressed small color="#1e9ff2" class="mr-3" style="min-height: 2.5rem"
          :class="!nofilter ? 'white--text' : ''" :disabled="nofilter" @click="showVoucherList">
          {{ $t("SigninVoucherList.title_show") }}
        </v-btn>
        <v-btn depressed dark small color="#666ee8" style="min-height: 2.5rem" @click="resetVoucherList">
          <v-icon>mdi-autorenew</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-tabs-items v-if="!loadingData" v-model="current_tab" vertical>
      <!-- voucherlist -->
      <v-tab-item value="voucherlist" class="px-2">
        <v-row style="margin-top: -2%">
          <v-col cols="12">
            <div class="">
              <v-row align="center" justify="center" class="mt-6 mb-2 mx-2">
                <v-col cols="6">
                  <span class="grey--text">{{
                    $t("SigninVoucherList.item_per_page")
                  }}</span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark text v-bind="attrs" v-on="on" color="primary" class="ml-2">
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index"
                        @click="updateItemsPerPage(number)">
                        <v-list-item-title>{{ number }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="6" align="end">
                  <span class="mr-4 grey--text">
                    Page {{ page }} of {{ numberOfPages }}
                  </span>
                  <v-btn icon class="mr-1" @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn icon class="ml-1" @click="nextPage">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-data-table height="550px" width="100%" :headers="headers" :items="vouchers" item-key="id" :page="page"
                :items-per-page.sync="itemsPerPage" :loading="data_loading" :no-data-text="$t('SignupStep.nodata')"
                fixed-header hide-default-footer class="mb-12" @click:row="(item) => clickVoucher(item)">
                <!-- <template v-slot:header="{ props }">
                    <th
                      style="background: white; z-index: 1;"
                      class="text-header mx-1 pb-3"
                      v-for="(header, index) in props.headers" :key="index">
                      {{ header.text }}
                    </th>
                  </template> -->
                <template v-slot:[`item.voucher_number`]="{ item }">
                  <p class="text-info text-bold">{{ item.voucher_number }}</p>
                </template>
                <template v-slot:[`item.order_date`]="{ item }">
                  <span class="text-normal">{{ item.ordered_date }}</span>
                </template>
                <template v-slot:[`item.guest`]="{ item }">
                  <p class="text-info text-bold">
                    {{ item.guest.first_name }} {{ item.guest.last_name }}
                  </p>
                  <div v-if="item.person">
                    <span class="text-subinfo text-bold mr-1">{{ item.person }}</span>
                    <span class="text-subinfo text-bold" v-if="item.person > 1">{{
                      $t("SigninVoucherList.guest_unit")
                    }}</span>
                    <span class="text-subinfo text-bold" v-else>{{
                      $t("SigninVoucherList.guest_unit")
                    }}</span>
                  </div>
                </template>
                <template v-slot:[`item.room_type`]="{ item }">
                  <div class="badge-style">
                    <span class="text-badge">{{ item.room_type }}</span>
                  </div>
                  <p class="text-subinfo text-bold">{{ item.rate_plan_name }}</p>
                </template>
                <template v-slot:[`item.booking_date`]="{ item }">
                  <div v-if="item.booking_date">
                    <span class="text-normal">{{ item.booking_date }}</span>
                  </div>
                  <div v-else>
                    <span class="text-normal">-</span>
                  </div>
                </template>
                <template v-slot:[`item.stay_date`]="{ item }">
                  <div v-if="item.stay_date" class="text-normal">
                    {{ item.stay_date }}
                  </div>
                  <div v-else>
                    <span class="text-normal">-</span>
                  </div>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  <span>{{ item.received ? `฿ ${item.received}` : "-" }}</span><br />
                  <span class="text-subinfo text-error" v-if="item.type === 'team_purchase'">{{
                    $t("SigninTeamPurchase.title")
                  }}</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-icon small class="mr-2" :color="item.status_color">mdi-record-circle-outline</v-icon>
                  <span class="text-normal">{{
                    $t(`SigninPayout.${item.status
                  }`) ||
                  item.status ||
                  $t("SignupStep.nodata")
                  }}</span>
                </template>
              </v-data-table>
            </div>
          </v-col>
          <div style="
              position: absolute;
              width: 100%;
              bottom: 0;
              left: 0;
              background: white;
              margin-top: 10px;
            ">
            <div class="ma-2 pa-2 d-flex flex-row align-center justify-space-between"
              style="border-top: 1px solid #ababab">
              <div>
                {{ $t("SigninVoucherList.title_count") }} {{ vouchers.length }}
                {{ $t("SigninVoucherList.title_records") }}
              </div>
              <div>
                {{ $t("SigninVoucherList.title_total_amount") }} {{ total_hotel }}
                {{ $t("SigninVoucherList.title_thb") }}
              </div>
            </div>
          </div>
        </v-row>
      </v-tab-item>
      <!-- voucherdetail -->
      <v-tab-item value="voucherdetail">
        <div class="card">
          <v-row row wrap>
            <v-col cols="5">
              <v-card class="voucher-list">
                <v-row row wrap style="
                    height: 55px;
                    margin: 0px;
                    position: absolute;
                    top: 10px;
                    left: 50px;
                    z-index: 99;
                    width: 80%;
                  ">
                  <v-col cols="6">
                    <v-icon class="mb-1" small left>mdi-file-outline</v-icon>
                    PDF / Print
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" align="end">
                    <v-icon class="mb-1" small left>mdi-email-outline</v-icon>
                    Mail
                  </v-col>
                </v-row>
                <v-data-table height="750px" v-model="selected" :headers="header_list" :items="vouchers" item-key="id"
                  show-select class="pt-2 mb-12" fixed-header hide-default-footer
                  @click:row="(item) => clickVoucher(item)">
                  <template v-slot:[`item.voucher_number`]="{ item }">
                    <v-row row wrap>
                      <v-col cols="6">
                        <div class="ml-0 mt-2" style="font-weight: 700">
                          {{ property_name }}
                        </div>
                      </v-col>
                      <v-col cols="6" align="end">
                        <div class="mt-2" style="color: #6c757d; font-size: 12px" v-if="item.total != 0">
                          <b>THB {{ item.received }}</b>
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <div style="font-size: 12px; margin-top: -20px">
                          <div style="color: #1e9ff2">No. {{ item.voucher_number }}</div>
                          <div class="text-muted">Stay Date: {{ item.stay_date }}</div>
                        </div>
                      </v-col>
                      <v-col cols="5" align="end">
                        <div style="color: #28d094; font-size: 12px; margin-top: -20px">
                          {{ item.status_text }}
                        </div>
                        <v-icon small>mdi-email-outline</v-icon>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col cols="7">
              <div v-if="show_overlay">
                <v-overlay>
                  <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
              </div>
              <div v-else>
                <VoucherDetail :voucherDetail="voucher_detail" />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-right-transition">
      <v-card> History </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import VoucherDetail from "@/components/VoucherList/VoucherDetail.vue";

export default {
  name: "SigninVoucherScreen",
  metaInfo: {
    title: "Voucher List",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    EventBus.$emit("endLoadingFunc");
    next();
  },
  components: {
    VoucherDetail
  },
  data() {
    let vm = this;
    return {
      search: "",
      filter: {},
      sortDesc: false,
      itemsPerPageArray: [10, 20, 50],
      page: 1,
      itemsPerPage: 10,
      userToken: "",
      propertyID: "",
      date_of: "",
      type: "",
      status: "",
      voucher_no: "",
      start_menu: false,
      start_format: "",
      start_date: "",
      min_startdate: "",
      end_menu: false,
      end_format: "",
      end_date: "",
      min_enddate: "",
      nofilter: true,
      data_loading: false,
      current_tab: null,
      dialog: false,
      item_date: [
        { text: "All", value: "" },
        { text: "Ordered", value: "ordered" },
        { text: "Booked", value: "booked" },
        { text: "Arrival", value: "arrival" },
      ],
      item_voucher: [{ text: "All", value: "" }],
      item_type: [
        { text: "All", value: "" },
        { text: "Buy Now", value: "buy_now" },
        { text: "Team Purchase", value: "team_purchase" },
      ],
      item_status: [
        { text: "Confirm Reservation", value: "confirm_reservation" },
        { text: "Paid Voucher", value: "paid_voucher" },
        { text: "Pending Voucher", value: "pending_voucher" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Expired", value: "expried" },
      ],
      headers: [
        { text: "Voucher No.", align: "center", value: "voucher_number" },
        { text: "Order Date", align: "center", value: "order_date" },
        { text: "Guest Name", align: "center", value: "guest" },
        { text: "Room Type", align: "center", value: "room_type" },
        { text: "Booking Date", align: "center", value: "booking_date" },
        { text: "Stay Date", align: "center", value: "stay_date" },
        { text: "Total Amount", align: "center", value: "total" },
        { text: "Status", align: "center", value: "status" },
      ],
      header_list: [{ text: "", value: "voucher_number", sortable: false }],
      selected: [],
      vouchers: [],
      voucher_detail: {},
      guest_detail: {},
      voucher_price: null,
      room_detail: {},
      property_name: "",
      show_overlay: false,
      loadingData: false,
      total_hotel: 0,
    };
  },
  created() {
    this.property_name = localStorage.getItem("property_name");
    this.data_loading = true;
    this.current_tab = "voucherlist";
    EventBus.$emit("loadingtillend");
    this.userToken = this.$route.params.userToken;
    this.propertyID = this.$route.params.propertyId;
    this.getVoucherListFirstFunc();
    // this.initAll()
  },
  mounted() {
    // this.getVoucherList()
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.vouchers.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  watch: {
    date_of() {
      if (this.date_of !== "") this.nofilter = false;
    },
    type() {
      if (this.type !== "") this.nofilter = false;
    },
    status() {
      if (this.status !== "") this.nofilter = false;
    },
    voucher_no() {
      if (this.voucher_no !== "") this.nofilter = false;
    },
    start_date() {
      if (this.start_date !== "" && this.end_date !== "") {
        this.nofilter = false;
      } else {
        this.nofilter = true;
      }
    },
    end_date() {
      if (this.start_date !== "" && this.end_date !== "") {
        this.nofilter = false;
      } else {
        this.nofilter = true;
      }
    },
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    initAll() {
      const self = this;
      self.getVoucherList();
    },
    async getVoucherListFirstFunc() {
      this.loadingData = true;
      var propertyinfo = `/voucher/list?property_id=${this?.propertyID || this?.$route?.params?.propertyId}`;

      var querydata = this.date_of
        ? `&date_of=${this?.date_of}`
        : "" + this.type
          ? `&type=${this?.type}`
          : "" + this.status
            ? `&status=${this?.status}`
            : "" + this.start_date
              ? `&from=${this?.start_date}`
              : "" + this.end_date
                ? `&to=${this?.end_date}`
                : "" + this.voucher_no
                  ? `&voucher_number=${this?.voucher_no}`
                  : "";
      var pathapi = querydata ? propertyinfo + querydata : propertyinfo;
      var tmpItems = [];
      try {
        const url = document.URL;
        const token = url.split("/")[5];

        const res = await this.axios.get(process.env.VUE_APP_API + pathapi, {
          headers: {
            Authorization: `Bearer ${token}` || self?.userToken || localStorage?.getItem("Token") || self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || this?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          tmpItems = res.data.result;
          if (res?.data?.result?.length) {
            var temp = res.data.result;
            // console.log(temp);
            this.vouchers = temp.map((el, i) => {
              this.item_voucher.push({
                text: el.voucher_number,
                value: el.voucher_number,
              });
              if (el.status)
                el.status_text =
                  this.$t(`SigninPayout.${el.status}`) || this.$t("SignupStep.nodata");
              if (el.status === "confirm_reservation") {
                el.status_color = "#28d094";
              } else if (el.status === "pending" || el.status === "pending_voucher") {
                el.status_color = "#666ee8";
              } else if (el.status === "cancelled") {
                el.status_color = "#ff4961";
              } else if (el.status === "expired") {
                el.status_color = "#ff9149";
              } else if (el.status === "paid_voucher") {
                el.status_color = "#1e9ff2";
              }
              return el;
            });
            this.total_hotel = this.sumBalance(this.vouchers);
            // console.log("voucher list", this.vouchers);
          }
        }
      } catch (error) {
        console.dir(error.response);
      } finally {
        if (!tmpItems?.length) {
          // swal(
          //   this.$t("Alert.warn_title"),
          //   this.$t("Alert.vouchernofound"),
          //   this.$t("Alert.warn_label"),
          //   {
          //     button: false,
          //     timer: 3000,
          //   }
          // );
        }
        EventBus.$emit("endloading");
        this.data_loading = false;
        this.loadingData = false;
      }
    },
    async getVoucherList() {
      // console.log("show");
      // console.log(this.status);
      const url = document.URL;
      const token = url.split("/")[5];
      this.loadingData = true;
      var propertyinfo = `/voucher/list?property_id=${this?.propertyID || this?.$route?.params?.propertyId}`;

      var querydata = '';

      if (this.date_of) {
        querydata += `&date_of=${this?.date_of}`;
      }
      if (this.type) {
        querydata += `&type=${this?.type}`;
      }
      if (this.status) {
        querydata += `&status=${this?.status}`;
      }
      if (this.start_date && this.end_date) {
        querydata += `&from=${this?.start_date}&to=${this?.end_date}`;
      }

      if (this.voucher_no) {
        querydata += `&voucher_number=${this?.voucher_no}`;
      }

      // var querydata = this.date_of
      //   ? `&date_of=${this?.date_of}`
      //   : "" + this.type
      //     ? `&type=${this?.type}`
      //     : "" + this.status
      //       ? `&status=${this.status?.toString()}`
      //       : "" + this.start_date
      //         ? `&from=${this?.start_date}`
      //         : "" + this.end_date
      //           ? `&to=${this?.end_date}`
      //           : "";
      var pathapi = querydata ? propertyinfo + querydata : propertyinfo;
      var tmpItems = [];
      try {
        const res = await this.axios.get(process.env.VUE_APP_API + pathapi, {
          headers: {
            Authorization: `Bearer ${token}` || self?.userToken || localStorage?.getItem("Token") || self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || this?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          tmpItems = res.data.result;
          if (res?.data?.result?.length) {
            var temp = res.data.result;
            // const lookup = temp.reduce((a, e) => {
            //   console.log(e)
            //   a[e.voucher_number] = ++a[e.voucher_number] || 0
            //   return a
            // }, {})
            // console.log(lookup)
            // console.log(temp.filter(e => lookup[e.voucher_number]))
            this.vouchers = temp.map((el, i) => {
              if (el.status === "confirm_reservation") {
                el.status_color = "#28d094";
                el.status_text = "Confirm reservation";
              } else if (el.status === "pending" || el.status === "pending_voucher") {
                el.status_color = "#666ee8";
                el.status_text = "Pending voucher";
              } else if (el.status === "cancelled") {
                el.status_color = "#ff4961";
                el.status_text = "Cancelled";
              } else if (el.status === "expired") {
                el.status_color = "#ff9149";
                el.status_text = "Expired";
              } else if (el.status === "paid_voucher") {
                el.status_color = "#1e9ff2";
                el.status_text = "Paid voucher";
              }
              return el;
            });
            // console.log("voucher list", this.vouchers);
            this.total_hotel = this.sumBalance(this.vouchers);
          }
        }
      } catch (error) {
        console.dir(error.response);
      } finally {
        if (!tmpItems?.length) {
          // swal(
          //   this.$t("Alert.warn_title"),
          //   this.$t("Alert.vouchernofound"),
          //   this.$t("Alert.warn_label"),
          //   {
          //     button: false,
          //     timer: 3000,
          //   }
          // );
        }
        EventBus.$emit("endloading");
        this.data_loading = false;
        this.loadingData = false;
      }
    },
    sumBalance(data) {
      var sum = 0;
      data.forEach((el) => {
        sum = parseFloat(sum) + parseFloat(el.voucher_price_net);
      });
      return this.priceFormatter(sum);
    },
    priceFormatter(value) {
      return Number(value || 0)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    clearFilter() {
      this.date_of = "";
      this.type = "";
      this.status = "";
      this.start_date = "";
      this.end_date = "";
      this.start_format = "";
      this.end_format = "";
      this.nofilter = true;
    },
    showVoucherList() {
      this.vouchers = [];
      this.data_loading = true;
      this.getVoucherList();
    },
    resetVoucherList() {
      this.vouchers = [];
      this.clearFilter();
      this.data_loading = true;
      this.getVoucherList();
    },
    formatStart(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.start_format = `${day}/${month}/${year}`;
      this.start_menu = false;
      this.min_enddate = date;
    },
    formatEnd(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      this.end_format = `${day}/${month}/${year}`;
      this.end_menu = false;
    },
    clickVoucher(item) {
      this.show_overlay = true;
      this.current_tab = "voucherdetail";
      this.getVocuherDetail(item._id);
      EventBus.$emit("loadingtillend");
    },
    async getVocuherDetail(voucher_id) {
      try {
        const url = document.URL;
        const token = url.split("/")[5];

        const res = await this.axios.get(
          process.env.VUE_APP_API + `/voucher/detail?voucher_id=${voucher_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}` || self?.userToken || localStorage?.getItem("Token") || self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || this?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          setTimeout(() => {
            this.show_overlay = false;
          }, 500);
          this.voucher_detail = res.data.result;
          this.guest_detail = this.voucher_detail.guest;
          this.voucher_price = this.voucher_detail.total.toFixed(2);
          this.room_detail = this.voucher_detail.room_detail;
          // console.log("voucher detail", this.voucher_detail);
        }
      } catch (error) {
        console.dir(error.response);
      } finally {
        EventBus.$emit("endloading");
        this.data_loading = false;
      }
    },
    backtoOverview() {
      this.current_tab = "voucherlist";
      this.initAll();
    },
  },
};
</script>

<style>
/* .v-text-field .v-input__slot {
  max-height: 2.5rem !important;
} */
.v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense .v-select__selections {
  max-height: 2.5rem !important;
}

.v-text-field .v-input__control {
  max-height: 2.5rem !important;
}

@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.positionbtn {
  position: absolute;
  bottom: 20px;
}

.btnshowvoucher {
  height: 40px;
  border-radius: 5px;
  border-color: #1e9ff2 !important;
  background-color: #1e9ff2 !important;
  box-shadow: 0px 1px 20px 1px rgb(30 159 242 / 60%);
}

.btnresetvoucher {
  height: 40px;
  border-radius: 5px;
  border-color: #666ee8 !important;
  background-color: #666ee8 !important;
  box-shadow: 0px 1px 20px 1px rgb(30 159 242 / 60%);
}

.v-autocomplete__content {
  top: 254px !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  /* height: 550px; */
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

th {
  background: white;
  position: sticky;
  top: 0;
  /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid #ababab;
}

.text-header {
  padding: 3% 0 0 0;
  font-size: 14px;
  text-align: center;
}

.text-info {
  color: #1e9ff2 !important;
  font-size: 14px;
  margin: 0;
  line-height: 1.4;
  font-weight: 400;
  text-transform: capitalize;
}

.text-subinfo {
  color: #6b6f82 !important;
  font-size: 11px !important;
}

.text-normal {
  font-size: 14px !important;
  margin: 0;
  line-height: 1.4;
  font-weight: 400;
  font-style: normal;
  color: #111111;
}

.text-error {
  color: #ff4961 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.badge-style {
  display: inline-block;
  background-color: #666ee8;
  margin: 15px 0 0 0;
  padding: 0.35em 0.4em;
  font-size: 80%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  width: 120px;
}

.badge-style .text-badge {
  font-size: 11px !important;
  color: #fff;
  word-wrap: break-word;
}

.btnbackvoucher {
  cursor: pointer;
  font-size: 12px !important;
  margin-top: -28px;
  margin-bottom: 10px;
}

.text-voucherno {
  font-size: 21px !important;
  font-weight: bold;
}

.btnhistory {
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 600;
}

.card {
  border: 0;
  border-radius: 0;
  height: 750px;
  margin-bottom: 50px;
}

.card {
  margin-bottom: 1.875rem;
  border: none;
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
  border-radius: 0.45rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.35rem;
}

.page {
  min-height: 297mm;
  padding: 10mm;
  margin: 2mm auto;
  border: 1px #d3d3d3 solid;
  background: white;
}

.topic_small {
  font-size: 12px;
  color: #6c757d;
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
}

.detail {
  font-size: 14px;
  color: #414042;
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
}

.detail_bold {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #000;
  margin: 0;
  line-height: 1.4;
  font-style: normal;
}

.detail_normal {
  font-size: 18px !important;
  color: #000;
  margin: 0;
  line-height: 1.4;
  font-style: normal;
}

.voucher-list {
  box-sizing: border-box;
  /* border: 1px solid #ababab; */
  height: 750px;
}

.voucher-detail {
  font-family: "Quicksand", Georgia, "Times New Roman", Times, serif !important;
}

.text-picker {
  font-size: 14px !important;
}
</style>
